import React from 'react';
import { ContentWrapper, ImageWrapper, SectionWrapper } from './styles';

const Section = ({ image, title, description, direction = 'right' }) => {
  return (
    <SectionWrapper data-testid="section-wrapper" left={direction === 'left'}>
      <ImageWrapper>
        <img src={image} alt={title} />
      </ImageWrapper>
      <ContentWrapper>
        <h2>{title}</h2>
        <p>{description}</p>
      </ContentWrapper>
    </SectionWrapper>
  );
};

export default Section;
