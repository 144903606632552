import React, { useState, useRef } from 'react';
import { Icon } from '@midway/web-ui-component';
import { trackingSelectContent } from '~/analytics';
import { convertStringTagbook } from '~/utils/utils';
import { List, IconChevron, ButtonWrapper, RightIconContainer } from './styles';

// eslint-disable-next-line react/prop-types
const Questions = ({ title, content, rightIcon, onToggle = () => {} }) => {
  const [isActive, setIsActive] = useState(false);
  const [height, setHeight] = useState(0);

  const contentRef = useRef(null);

  function toggleAccordion() {
    const willBeActive = !isActive;
    onToggle(willBeActive);
    setIsActive(willBeActive);

    trackingSelectContent(
      !willBeActive
        ? `faq:fechar:${convertStringTagbook(title)}`
        : `faq:abrir:${convertStringTagbook(title)}`,
      'emprestimos'
    );
    setHeight(willBeActive ? contentRef.current.scrollHeight : 0);
  }

  function handleKeyPress(event) {
    if (event.key === 'Enter' || event.key === ' ') {
      toggleAccordion();
      event.preventDefault();
    }
  }

  const accordionClassName = isActive ? 'accordion active' : 'accordion';
  const acordionContent = isActive
    ? 'accordion-content border-color'
    : 'accordion-content';

  return (
    <List data-testid="questions-component">
      <div
        className={accordionClassName}
        onClick={toggleAccordion}
        onKeyDown={handleKeyPress}
        tabIndex={0}
        role="button"
        aria-expanded={isActive}
        data-testid="accordion-header"
      >
        {!!rightIcon && <RightIconContainer>{rightIcon}</RightIconContainer>}
        <p className="accordion-title" data-testid="accordion-title">
          {title}
        </p>
        <ButtonWrapper>
          <IconChevron>
            <Icon
              icon={['fa', 'chevron-down']}
              size="2x"
              rotate180={isActive}
              color={isActive ? '#1F2B2A' : '#C2C2C2'}
            />
          </IconChevron>
        </ButtonWrapper>
      </div>

      <div
        ref={contentRef}
        style={{
          maxHeight: `${height}px`,
          marginBottom: 16,
        }}
        className={acordionContent}
        data-testid="accordion-content"
      >
        <div
          className="accordion-text"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: content }}
          data-testid="accordion-text-ex"
        />
      </div>
    </List>
  );
};

export default Questions;
