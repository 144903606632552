import React, { useCallback, useEffect, useState } from 'react';
import RenderBannerImage from '~/components/RenderBannerImage';
import { banner } from './data';
import cmsService from '~/services/cms/pages';
import * as S from './styles';

const HeaderBanner = () => {
  const [bannerData, setBannerData] = useState([]);

  const handleGetBannerData = useCallback(async () => {
    try {
      const response = await cmsService.getBannerMidwayAccountRates(
        process.env.REACT_APP_SITE_CMS_ENV
      );

      if (response) {
        setBannerData(response);
        return;
      }
      setBannerData(banner);
    } catch (error) {
      setBannerData(banner);
    }
  }, []);

  useEffect(() => {
    handleGetBannerData();
  }, [handleGetBannerData]);

  const banners = bannerData[0] || banner;

  return (
    <S.BannerContainer>
      <RenderBannerImage {...banners} />
    </S.BannerContainer>
  );
};

export default React.memo(HeaderBanner);
