import React, { useCallback, useRef, useState } from 'react';
import { IconLock } from '@tabler/icons-react';
import Button from '~/pages/PaymentChannel/components/Button';
import ImgSrc from '~/assets/img/PaymentChannel/hero.png';
import MaterialInput from '~/components/MaterialInput';
import {
  maskCpf,
  maskDate,
  validarCPF,
  validarDataNascimento,
} from '~/utils/utils';
import * as S from './styles';

const CPF_LENGTH = 14;
const DATE_LENGTH = 10;

const SectionHero = ({ searchCallback, isLoading }) => {
  const [cpf, setCpf] = useState();
  const [cpfError, setCpfError] = useState('');
  const [dateError, setDateError] = useState('');
  const [date, setDate] = useState();
  const [showDate, setShowDate] = useState(false);
  const dateInputWrapperRef = useRef(null);

  const onChangeCpf = useCallback(
    e => {
      const inputValue = e.target.value;
      const cpfHasError =
        inputValue.length === CPF_LENGTH && !validarCPF(inputValue);
      const shouldShowDate = inputValue.length === CPF_LENGTH && !cpfHasError;
      setCpf(inputValue);
      setCpfError(cpfHasError ? `CPF inválido` : '');
      setShowDate(shouldShowDate);
    },
    [setCpf, setCpfError, setShowDate]
  );

  const onChangeDate = useCallback(
    e => {
      const inputValue = e.target.value;
      const dateHasError =
        inputValue.length === DATE_LENGTH && !validarDataNascimento(inputValue);
      setDate(inputValue);
      setDateError(dateHasError ? 'Data de nascimento inválida' : '');
    },
    [setDate, setDateError]
  );

  const onClickHandler = () => {
    searchCallback(cpf, date);
  };

  const dateInputWrapperHeight = showDate
    ? `${dateInputWrapperRef.current.scrollHeight}px`
    : '0px';

  const handleKeyDown = event =>
    event.key === 'Enter' && searchCallback(cpf, date);

  const disabled =
    dateError?.length !== 0 ||
    cpfError?.length !== 0 ||
    cpf?.length !== CPF_LENGTH ||
    date?.length !== DATE_LENGTH ||
    isLoading;

  return (
    <S.Wrapper id="section-hero">
      <S.Container>
        <S.ContentWrapper>
          <S.SmallTextWrapper>
            <IconLock />
            <S.SmallText>Garantia de segurança Riachuelo</S.SmallText>
          </S.SmallTextWrapper>
          <S.Title as="h1">
            Acesse a fatura do Cartão <span aria-label="Riachuelo">RCHLO</span>{' '}
            e negocie dívidas
          </S.Title>
          <S.Content>
            <S.CpfInputWrapper>
              <MaterialInput
                onChange={onChangeCpf}
                label="CPF"
                maskFn={maskCpf}
                errorMessage={cpfError}
                id="cpf"
              />
            </S.CpfInputWrapper>
            <S.DateInputWrapper
              style={{ height: dateInputWrapperHeight }}
              ref={dateInputWrapperRef}
            >
              <MaterialInput
                onChange={onChangeDate}
                label="Data de nascimento"
                maskFn={maskDate}
                errorMessage={dateError}
                id="data-nascimento"
                onKeyDown={handleKeyDown}
              />
            </S.DateInputWrapper>
            <Button
              disabled={disabled}
              onClick={onClickHandler}
              isLoading={isLoading}
              data-testid={'consultar'}
            >
              Consultar
            </Button>
          </S.Content>
        </S.ContentWrapper>
        <S.Image src={ImgSrc} />
      </S.Container>
    </S.Wrapper>
  );
};

export default SectionHero;
