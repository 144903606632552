import React, { useEffect } from 'react';
import * as S from './styles';
import { usePaymentChannelContext } from '~/context/PaymentChannel';
import { trackingViewContent } from '~/analytics';
import { flow, contentPage } from '~/analytics/PaymentChannel';
import HeaderContracts from '~/pages/PaymentChannel/components/HeaderContracts';
import { first, words } from 'lodash';
import { usePrepareCardInfo } from '../../hooks/usePrepareCardInfo';

const Contracts = () => {
  const { paymentChannelData, updateData } = usePaymentChannelContext();
  const { listContracts } = paymentChannelData;

  const prepareCardInfo = usePrepareCardInfo(updateData, contentPage.contracts);

  useEffect(() => {
    trackingViewContent(contentPage.contracts, flow.default);
  }, []);

  return (
    <>
      <HeaderContracts
        name={first(words(paymentChannelData?.contracts[0]?.name))}
      />
      <S.Wrapper>
        <S.Container>
          {listContracts?.map((item, index) => (
            <S.ItemContract key={index} onClick={() => prepareCardInfo(item)}>
              <S.ItemRow>
                <S.ItemTitle>{item.name}</S.ItemTitle>
                <S.IconChevronRight />
              </S.ItemRow>
              <S.ItemRow>
                {item.vencimento && (
                  <S.ItemText>Venc {item.vencimento}</S.ItemText>
                )}
                <S.Badge type={item.status}>{item.statusText}</S.Badge>
              </S.ItemRow>
            </S.ItemContract>
          ))}
        </S.Container>
      </S.Wrapper>
    </>
  );
};

export default Contracts;
