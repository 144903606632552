import { useCallback } from 'react';
import { screens } from '~/pages/PaymentChannel/routes';
import { useHistory } from 'react-router-dom';
import { first } from 'lodash';
import { flow, contentType } from '~/analytics/PaymentChannel';
import { trackingSelectContent } from '~/analytics';

export const usePrepareCardInfo = (updateData, contentPage) => {
  const navigate = useHistory();
  const cp = contentPage;

  return useCallback(
    data => {
      const trackingSelectedContract = selectedContract => {
        let ct = contentType.contractTypeOthers;
        if (selectedContract.type === 'loan') ct = contentType.contractTypeLoan;
        if (selectedContract.productDescription === 'Cartão PL')
          ct = contentType.contractTypeCardPl;
        if (
          selectedContract.productDescription === 'Cartão Visa' ||
          selectedContract.productDescription === 'Cartão Master'
        )
          ct = contentType.contractTypeCardPl;
        trackingSelectContent(ct, flow.default, cp);
      };
      const item = first(data) || data;

      const isOverDue = item?.isOverdue || item?.overdueDays > 0;
      const daysOverdue = item?.overDueDays || item?.overdueDays;
      const selected = item.contract || item.id;

      trackingSelectedContract(item);

      updateData('cardInfo', { isOverDue, daysOverdue });
      updateData('selected', selected);
      updateData('system', item?.system);

      navigate.push(screens.CARDS_HOME);
    },
    [updateData, navigate, cp]
  );
};
