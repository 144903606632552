import React from 'react';
import { Button } from '@midway/web-ui-component';
import moment from 'moment';
import { upperCase } from 'lodash';
import { useHistory } from 'react-router-dom';

import { usePaymentChannelContext } from '~/context/PaymentChannel';
import format from '~/services/format';
import { IconCircleFilled } from '@tabler/icons-react';
import * as S from './styles';
import { trackingSelectContent } from '~/analytics';
import { contentPage, contentType, flow } from '~/analytics/PaymentChannel';
import { screens } from '~/pages/PaymentChannel/routes';

const CardInfoCard = ({ data, isLoading }) => {
  const navigate = useHistory();
  const { paymentChannelData } = usePaymentChannelContext();
  const { listContracts, selected, currentInvoiceCard, cardInfo } =
    paymentChannelData;

  const percentualUtilizacao =
    (data?.limitOccupation / data?.creditLimit) * 100;

  const colorRanges = [
    { max: 50, color: '#4CAF50' },
    { max: 80, color: '#FFC107' },
    { max: 100, color: '#FF5722' },
    { max: Infinity, color: '#D32F2F' },
  ];

  const color =
    colorRanges.find(range => percentualUtilizacao <= range.max)?.color ||
    '#4CAF50';

  const cardSelected = listContracts?.find(item => item?.id === selected);

  const onClickHandler = () => {
    trackingSelectContent(
      contentType.payBill,
      flow.cards,
      contentPage.cardsHome,
      {
        contrato: paymentChannelData?.selected,
      }
    );
    navigate.push(screens.RESUME_TYPES);
  };

  return (
    <S.Wrapper>
      <S.HeaderContainer>
        <S.TitleContainer>
          <S.Title>{cardSelected?.name}</S.Title>
        </S.TitleContainer>
      </S.HeaderContainer>
      <S.LimitContainer>
        <S.ProgressBar
          barColor={color}
          percent={Math.min(percentualUtilizacao, 100)}
        />
        <S.LimitText>
          <IconCircleFilled />
          Limite disponível{' '}
          <S.LimitValue color={color}>
            {format.currency(data?.limitAvailable)}
          </S.LimitValue>
        </S.LimitText>
      </S.LimitContainer>
      <S.InfoContainer>
        {currentInvoiceCard?.invoiceResponse && !cardInfo.isOverDue && (
          <S.InfoItem>
            <S.InfoDescription>Compras até</S.InfoDescription>
            <S.InfoValue>
              {upperCase(
                moment(data?.cardDateDetail?.bestDayPurchase).format('D MMM')
              )}
            </S.InfoValue>
          </S.InfoItem>
        )}
        <S.InfoItem>
          <S.InfoDescription>Vencimento</S.InfoDescription>
          <S.InfoValue>
            {upperCase(moment(data?.cardDateDetail?.dueDate).format('D MMM'))}
          </S.InfoValue>
        </S.InfoItem>
        <S.InfoItem>
          <S.InfoDescription>Valor a pagar</S.InfoDescription>
          <S.InfoValue>{format.currency(data?.value)}</S.InfoValue>
        </S.InfoItem>
      </S.InfoContainer>
      {
        <S.ButtonsContainer>
          <Button
            block
            state="black"
            title="Pagar ou parcelar fatura"
            aria-label="Pagar ou parcelar fatura"
            size="lg"
            disabled={isLoading}
            onClick={onClickHandler}
          />
        </S.ButtonsContainer>
      }
    </S.Wrapper>
  );
};

export default CardInfoCard;
