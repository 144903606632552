import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import CryptoJS from 'crypto-js';
import moment from 'moment/moment';
import { includes, keys } from 'lodash';

import useRecaptchaMiAjuda from '~/hooks/useRecaptcha';
import { usePaymentChannelContext } from '~/context/PaymentChannel';

import CollectionApi from '~/services/CollectionApi';
import { negotiable } from '~/pages/PaymentChannel/constants';
import { messageErrors } from '~/pages/PaymentChannel/constants';
import useInsiderQueue from '~/hooks/useInsiderQueue';
import { getUserName } from '~/pages/PaymentChannel/helpers';
import NoContractError from '~/pages/PaymentChannel/errors/NoContractError';

const useContracts = ({ documentNumber, birthDate }) => {
  const { toogles } = useSelector(state => state.featuretoggle);
  const { handleReCaptchaVerify } = useRecaptchaMiAjuda('miajuda');
  const { updateData, setLoading } = usePaymentChannelContext();
  const { insiderQueueUser, insiderQueueInit } = useInsiderQueue();

  const togglesContracts = {
    SICC: toogles.isEnabledNegotiationSicc,
  };

  const queryFn = async ({ documentNumber, birthDate }) => {
    const formatBD = moment(birthDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
    const document = documentNumber?.replace(/[.-]/g, '');

    const mdm = await CollectionApi.getMDM(document);
    const { firstName, surname } = getUserName(mdm?.fullName || '');

    insiderQueueUser(
      {
        uuid: mdm?.customerUuid || '',
        name: firstName || '',
        surname: surname || '',
        birthday: formatBD,
        custom: {
          cpf: CryptoJS.MD5(document).toString(),
        },
      },
      CryptoJS.MD5(document).toString()
    );
    insiderQueueInit();

    const token = await handleReCaptchaVerify();
    const response = await CollectionApi.getContracts(
      document,
      formatBD,
      token
    );

    if (!response?.contracts || response.error) {
      if (response.message === messageErrors.no_contract) {
        throw new NoContractError(response.message);
      }
      throw new Error(response.message ?? response.error);
    }

    const newContracts = response.contracts.filter(
      contract =>
        includes(keys(negotiable), contract.system) &&
        togglesContracts[contract.system]
    );

    const getProductName = (type, description) => {
      switch (type) {
        case 'loan':
          return 'Empréstimo';
        case 'cards':
          return description === 'Cartão PL'
            ? 'Cartão Riachuelo'
            : 'Cartão de crédito - Bandeira Master';
        default:
          return '';
      }
    };

    const filteredContracts = newContracts.map(contract => ({
      name: getProductName(contract?.type, contract?.productDescription),
      isOverdue: contract?.overdueDays > 0,
      overDueDays: contract?.overdueDays,
      productDescription: contract?.productDescription,
      statusText:
        contract?.overdueDays > 0
          ? `Atraso de ${contract?.overdueDays} dias`
          : 'Em dia',
      status: contract?.overdueDays > 0 ? 'late' : 'open',
      icon:
        contract?.product === '3'
          ? 'master'
          : contract?.product === '2'
            ? 'visa'
            : '',
      id: contract?.contract,
      system: contract?.system,
    }));

    updateData('user', {
      document: document,
      birthDate: formatBD,
    });

    updateData('contracts', newContracts);
    updateData('mdm', mdm);
    updateData('listContracts', filteredContracts);
    setLoading(false);

    return newContracts;
  };

  return useQuery({
    queryKey: ['contracts', documentNumber, birthDate],
    queryFn: () => queryFn({ documentNumber, birthDate }),
    enabled: Boolean(documentNumber && birthDate),
    refetchOnWindowFocus: false,
  });
};

export default useContracts;
