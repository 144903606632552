import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Icon } from '@midway/web-ui-component';
import { trackingSelectContent } from '~/analytics';
import ListStepperNumber from '~/components/ListStepperNumber';
import { faqCampanhaFgts } from '~/constants/faq';

import IphoneFgts from '~/assets/img/lending/IphoneFgts.png';
import PlayStore from '~/assets/img/lending/play-store.svg';
import AppleStore from '~/assets/img/lending/apple-store.svg';
import banner from '~/assets/img/lending/banner-fgts.png';
import bannerMobile from '~/assets/img/lending/banner-fgts-mobile.png';
import ImageVideo from '~/assets/img/Financial/fgts-anniversary-advance/video.png';

import camera from '~/assets/img/lending/camera.png';
import dollar from '~/assets/img/lending/dollar.png';
import percent from '~/assets/img/lending/percent.png';
import smartphone from '~/assets/img/lending/smartphone.png';
import iphoneMidway from '~/assets/img/lending/iphoneMidway.png';
import Modal from './components/Modal';
import DownloadStoreButton from './components/DownloadStoreButton';

import * as S from './styles';
import Questions from './components/Questions';

const STEP_LIST = [
  {
    text: 'Baixe o app FGTS e acesse Sistemática do saque do FGTS no menu principal;',
  },
  {
    text: 'Confira as regras de funcionamento, aceite os termos e clique em Optar pelo Saque-aniversário;',
  },
  {
    text: 'Na tela principal escolha Mais, selecione Autorização de consultas às informações do FGTS;',
  },
  {
    text: 'Leia os termos e clique em Continuar, pesquise por Midway S.A. clicando em adicionar instituição e confirme a seleção;',
  },
  {
    text: 'Conclua a operação clicando em SIM e agora acesse o app Midway para seguir com a contratação;',
  },
];

const cards = [
  {
    text: 'Taxa de juros de 1,79% ao mês',
    icon: percent,
  },
  {
    text: 'Não compromete sua renda mensal',
    icon: dollar,
  },
  {
    text: 'Dinheiro rápido mesmo para negativados',
    icon: camera,
  },
  {
    text: 'Rápido e simples contrate pelo App Midway',
    icon: smartphone,
  },
];

const LendingFgts = () => {
  const [showYoutubeVideo, setShowYoutubeVideo] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showMore, setShowMore] = useState(false);

  const handlerClickMobile = () => {
    window.open(
      'https://www.midway.com.br/app/?utm_source=site-midway&utm_medium=qr-code&utm_campaign=tela-emprestimos',
      '_blank'
    );
  };

  const handlerDownloadMidway = () => {
    if (isMobile) {
      handlerClickMobile();
    } else {
      setShowModal(true);
    }
  };
  const length = isMobile
    ? showMore
      ? faqCampanhaFgts.length
      : 4
    : faqCampanhaFgts.length;

  const handlerTag = contentType => {
    trackingSelectContent(contentType, 'emprestimos');
  };

  return (
    <S.Container>
      <S.ContainerGlobal>
        <S.BannerContainer>
          <S.BannerDesktop src={banner} />

          <S.BannerMobile src={bannerMobile} />
          <S.ContainerTextImg>
            <S.TextImage>
              Precisando de dinheiro? Seu FGTS está te esperando. Antecipe já!
              <S.ButtonCard
                data-testid="quero-agora-button"
                onClick={() => {
                  handlerTag('quero-agora');
                  handlerDownloadMidway();
                }}
              >
                Quero agora
              </S.ButtonCard>
            </S.TextImage>
          </S.ContainerTextImg>
        </S.BannerContainer>
        <S.ContainerCards>
          {cards.map((item, index) => (
            <S.CardIcon key={index}>
              <S.IconContainer>
                <S.IconCard src={item.icon} />
              </S.IconContainer>

              <S.TextIconCard>{item.text}</S.TextIconCard>
            </S.CardIcon>
          ))}
        </S.ContainerCards>

        <S.Content>
          <S.ContentTexts>
            <S.Title>
              Tire seus sonhos do papel, antecipe seu FGTS em poucos passos.
            </S.Title>
            <S.Subtitle>
              Na Midway você conta com facilidade e agilidade no processo de
              contratação, que é 100% digital. Além disso, não possui restrição
              de crédito e não tem prestação mensal.
            </S.Subtitle>
          </S.ContentTexts>

          <S.InformationPanel>
            <S.LeftPanel>
              <S.LeftImagePanel src={IphoneFgts} />
            </S.LeftPanel>
            <S.RightPanel>
              <S.CentralizeRightPanel>
                <S.TextPanel>
                  Para aderir ao Saque-Aniversário é simples!{' '}
                </S.TextPanel>
                <S.GroupLeft>
                  <ListStepperNumber data={STEP_LIST} />
                  <S.TextMidway>Baixe o aplicativo FGTS</S.TextMidway>
                </S.GroupLeft>

                <S.Agroup>
                  <DownloadStoreButton
                    icon={PlayStore}
                    storeName="Google Play"
                    onClick={() => handlerTag('download-google-play:app-fgts')}
                    link="https://play.google.com/store/apps/details?id=br.gov.caixa.fgts.trabalhador"
                  />

                  <DownloadStoreButton
                    icon={AppleStore}
                    storeName="Apple Store"
                    onClick={() => handlerTag('download-apple-store:app-fgts')}
                    link="https://apps.apple.com/br/app/fgts/id1038441027"
                  />
                </S.Agroup>
              </S.CentralizeRightPanel>
            </S.RightPanel>
          </S.InformationPanel>

          <S.BlockStyler>
            <S.LeftContainer>
              <S.BlockParagraph>
                Confira nosso vídeo de como simular e contratar o empréstimo
                saque aniversário FGTS no app Midway e contrate.
                <S.ButtonCard
                  data-testid="baixar-app-button"
                  onClick={() => {
                    handlerTag('baixar-app-midway');
                    handlerDownloadMidway();
                  }}
                >
                  Baixar app Midway
                </S.ButtonCard>
              </S.BlockParagraph>
            </S.LeftContainer>
            <S.RigthContainer>
              {showYoutubeVideo ? (
                <S.IframeYoutube
                  data-testid="iframe-youtube"
                  src="https://www.youtube.com/embed/1o9vxoOUWUE?autoplay=1&rel=0"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen="0"
                  showinfo="0"
                />
              ) : (
                <S.ButtonImage
                  data-testid="play-button"
                  onClick={() => {
                    setShowYoutubeVideo(true);
                    handlerTag('play-video');
                  }}
                >
                  <S.ImageVideo src={ImageVideo} />
                </S.ButtonImage>
              )}
            </S.RigthContainer>
          </S.BlockStyler>
          <S.ContainerMessageMidway>
            <S.LeftContainer>
              <S.ContainerAlingCenter>
                <S.TextAccount>Conta digital Midway</S.TextAccount>
                <S.SubText>
                  Baixe o nosso app para realizar a contratação do empréstimo
                  Antecipação Saque-Aniversário FGTS
                </S.SubText>
                <S.ContentPointText>
                  <S.SubTextPoint>
                    Baixe nosso app e realize a abertura da conta completa
                    grátis
                  </S.SubTextPoint>
                  <S.SubTextPoint>
                    Encontre a opção Empréstimos e selecione Saque aniversário
                    FGTS
                  </S.SubTextPoint>
                  <S.SubTextPoint>
                    Realize a simulação aceite os termos e finalize a
                    contratação
                  </S.SubTextPoint>
                </S.ContentPointText>
                {!isMobile && (
                  <S.ButtonDownloadApp
                    onClick={() => {
                      handlerTag('baixar-app-midway:conta-digital');
                      handlerDownloadMidway();
                    }}
                  >
                    Baixar app Midway
                  </S.ButtonDownloadApp>
                )}
              </S.ContainerAlingCenter>
            </S.LeftContainer>
            <S.RigthContainer>
              <S.RightImage src={iphoneMidway} />
              {isMobile && (
                <S.ButtonDownloadApp
                  data-testid="download-button"
                  onClick={() => handlerDownloadMidway()}
                >
                  Baixar app Midway
                </S.ButtonDownloadApp>
              )}
            </S.RigthContainer>
          </S.ContainerMessageMidway>

          <S.ContainerAccordion>
            <S.TitleQuestions>Perguntas frequentes</S.TitleQuestions>
            <S.Sized />
            {faqCampanhaFgts.slice(0, length).map(item => (
              <Questions
                content={item?.content}
                title={item?.title}
                key={item?.id}
              />
            ))}
          </S.ContainerAccordion>
          {isMobile && (
            <S.ContainerSeeMore
              onClick={() => {
                setShowMore(end => !end);
              }}
            >
              <S.SeeMOre>{showMore ? 'Ver menos' : 'Ver mais'} </S.SeeMOre>
              <Icon
                icon={['fa', 'chevron-down']}
                size="2x"
                rotate180={showMore}
                color="#704810"
              />
            </S.ContainerSeeMore>
          )}
        </S.Content>
      </S.ContainerGlobal>
      {showModal && (
        <Modal
          onCloseRequest={() => {
            handlerTag('fechar:modal-baixar-app-midway');
            setShowModal(false);
          }}
        />
      )}
    </S.Container>
  );
};

export default LendingFgts;
