export const flow = {
  default: 'central-de-pagamentos',
  cards: 'central-de-pagamentos:cartoes',
};

export const contentPage = {
  home: 'login',
  cardsHome: 'home',
  contracts: 'escolha-um-contrato',
  byPassContracts: 'by-pass-contrato',
  paymentOptions: 'opcoes-de-pagamento',
  installments: 'parcelar-pagamento',
  installmentsMoreOptions: 'mais-opcoes-de-parcelas',
  resumePaymentFull: 'resumo-pagamento-a-vista',
  resumePaymentInstallments: 'resumo-do-parcelamento',
  generatedPaymentFullPix: 'pagamento-a-vista-codigo-pix-gerado',
  generatedPaymentFullBillet: 'pagamento-a-vista-boleto-gerado',
  generatedInstallmentPaymentPix: 'parcelamento-codigo-pix-gerado',
  generatedInstallmentPaymentBillet: 'parcelamento-boleto-gerado',
  duplicatePix: 'acordo-existente-codigo-pix-gerado',
  duplicateBillet: 'acordo-existente-boleto-gerado',
};

export const contentType = {
  loginButton: 'consultar',
  contractTypeCardPl: 'cartao-rchlo',
  contractTypeLoan: 'emprestimo',
  contractTypeCreditCard: 'cartao-credito',
  contractTypeOthers: 'outros',
  payBill: 'pagar-ou-parcelar-fatura',
  copyPix: 'copiar-codigo-pix',
  copyBillet: 'copiar-codigo-boleto',
  shareQrCode: 'compartilhar-qr-code', // TODO aguardando implementação do botão de compartilhar
  decreaseInstallments: 'diminuir-parcelas',
  increaseInstallments: 'aumentar-parcelas',
  installmentsContinue: 'continuar',
  resumePaymentEditEntry: 'editar-entrada',
  resumePaymentEditInstallments: 'editar-parcelas',
  newSimulation: 'simular-novo-acordo',
  whatsappRchlo: 'whatsapp-home',
  centralPhone: 'telefone',
  blog: 'blog',
};
