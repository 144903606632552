import React from 'react';
import { useHistory } from 'react-router-dom';

import { Button } from '@midway/web-ui-component';

import format from '~/services/format';
import { usePaymentChannelContext } from '~/context/PaymentChannel';

import * as S from './styles';
import { screens } from '~/pages/PaymentChannel/routes';

const lasRangetOverDue = 180;
const midRangeOverDue = 77;

const DueCard = ({ daysOverdue, isLoading }) => {
  const navigate = useHistory();
  const { paymentChannelData } = usePaymentChannelContext();
  const { elegibility } = paymentChannelData;

  const overDueContent =
    daysOverdue >= lasRangetOverDue
      ? elegibility?.acquittance
      : elegibility?.debit;

  const installmentContentData = elegibility?.invoicement;

  return (
    <S.Wrapper>
      <S.Row>
        <S.Text>Valor principal em atraso</S.Text>
        <S.Money>{format.currency(overDueContent?.mainValue)}</S.Money>
      </S.Row>
      <S.Row>
        <S.Text>Juros por atraso</S.Text>
        <S.Money>{format.currency(overDueContent?.interestValue)}</S.Money>
      </S.Row>
      {overDueContent?.financialProductsValue > 0 && (
        <S.Row>
          <S.Text>Produtos financeiros</S.Text>
          <S.Money>
            {format.currency(overDueContent?.financialProductsValue)}
          </S.Money>
        </S.Row>
      )}

      <S.Separator />
      {daysOverdue <= midRangeOverDue ? (
        <S.InfoContainer>
          <S.Row>
            <S.Label>Com entrada de:</S.Label>
            <S.Value>
              {format.currency(installmentContentData?.minEntryValue)}
            </S.Value>
          </S.Row>
          <S.Row>
            <S.Label>Parcelamento:</S.Label>
            <S.Value>
              Até {installmentContentData?.maxInstallmentNumber}x
            </S.Value>
          </S.Row>
        </S.InfoContainer>
      ) : (
        <S.SavingsSection>
          {overDueContent?.discount?.maxTotal > 0 ? (
            <>
              <S.Badge type="big">
                Economize {format.currency(overDueContent?.discount?.maxTotal)}
              </S.Badge>
              <S.TotalText>
                de {format.currency(overDueContent?.totalValue)}
              </S.TotalText>
              <S.FinalTotal>
                por{' '}
                {format.currency(
                  overDueContent?.totalValue -
                    overDueContent?.discount?.maxTotal
                )}
              </S.FinalTotal>
            </>
          ) : (
            <>
              <S.FinalTotal>
                Valor total {format.currency(overDueContent?.totalValue)}
              </S.FinalTotal>
            </>
          )}
        </S.SavingsSection>
      )}

      <S.ButtonsContainer>
        <Button
          className="custom-button"
          block
          state="black"
          title={
            daysOverdue <= midRangeOverDue
              ? 'Pagar ou parcelar fatura'
              : 'Negociar dívida'
          }
          aria-label={
            daysOverdue <= midRangeOverDue
              ? 'Pagar ou parcelar fatura'
              : 'Negociar dívida'
          }
          size="medium"
          disabled={isLoading}
          onClick={() => {
            navigate.push(screens.RESUME_TYPES);
          }}
        />
      </S.ButtonsContainer>
    </S.Wrapper>
  );
};

export default DueCard;
